import React, { useEffect, useState } from "react";
import { Layout, Menu, Input, Switch, Avatar, Tooltip, Typography, Dropdown, message, Button } from "antd";
import {
  DashboardOutlined,
  ContactsOutlined,
  UserOutlined,
  SettingOutlined,
  SearchOutlined,
  BulbOutlined,
  BulbFilled,
  ScheduleOutlined,
  BookOutlined,
  BoldOutlined,
  DownSquareOutlined,
  CalendarOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { Menu as MenuIcon, X as CloseIcon } from 'lucide-react';
import Dashboard from "./components/Dashboard";
import Users from "./components/Users";
import Settings from "./components/Settings";
import ServiceForm from "./components/ServiceForm";
import InstructorDashboard from "./components/InstructorDashboard";
import ScheduleDashboard from "./components/ScheduleDashboard";
import TimeSlotDashboard from "./components/TimeSlotDashboard";
import LoginPage from "./components/LoginPage";
import ContactDashboard from "./components/ContactDashboard";
import EventDashboard from "./components/EventDashboard";
import PostDashboard from "./components/PostDashboard";
import BookingDashboard from "./components/BookingDashboard";
import CreateDashboard from "./components/CreateDashboard";
import Registration from "./components/Registration";
import SubscriberDashboard from "./components/SubscriberDashboard";
import PricingDashboard from './components/PricingDashboard';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const App = ({onCollapsedChange}) => {
  const [selectedMenu, setSelectedMenu] = React.useState("dashboard");
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const { SubMenu } = Menu;
  const [isAuthenticated, setIsAuthenticated] = React.useState(localStorage.getItem("isAuthenticated") === "true");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [collapsed, setCollapsed] = useState(isMobile);
  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);


    // Handle touch swipe for mobile
    const handleTouchStart = (e) => {
      setTouchStart(e.touches[0].clientX);
    };
  
    const handleTouchMove = (e) => {
      setTouchEnd(e.touches[0].clientX);
    };
  
    const handleTouchEnd = () => {
      if (!touchStart || !touchEnd) return;
      
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > 50;
      const isRightSwipe = distance < -50;
  
      if (isLeftSwipe && !collapsed) {
        setCollapsed(true);
      } else if (isRightSwipe && collapsed) {
        setCollapsed(false);
      }
  
      setTouchStart(null);
      setTouchEnd(null);
    };
  

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setIsSearchVisible(!mobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };





  useEffect(() => {
    // Retrieve last selected menu item
    const lastMenu = localStorage.getItem("selectedMenu");
    if (lastMenu) {
      setSelectedMenu(lastMenu);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setCollapsed(mobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuSelect = ({ key }) => {
    setSelectedMenu(key);
    localStorage.setItem("selectedMenu", key);
  };

  const handleLogin = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem("isAuthenticated", status);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    message.info("You have been logged out.");
  };

  if (!isAuthenticated) {
    return <LoginPage onLogin={handleLogin} />;
  }

  
  const renderContent = () => {
    switch (selectedMenu) {
      case "dashboard":
        return <Dashboard />;
      case "users":
        return <Users />;
      case "settings":
        return <Settings />;
      case "service":
        return <ServiceForm />;
      case "yoga-instructors":
        return <InstructorDashboard />;
      case "yoga-schedule":
        return <ScheduleDashboard />;
      case "Time-Slot":
        return <TimeSlotDashboard />;
      case "Contact":
        return <ContactDashboard />;
      case "Events":
        return <EventDashboard />;
      case "Post":
        return <PostDashboard />;
      case "BookingDashboard":
        return <BookingDashboard />;
      case "CreateDashboard":
        return <CreateDashboard />;
      case "Registration":
        return <Registration />;
      case "PricingDashboard":
          return <PricingDashboard />;
      case "Subscriber":
        return <SubscriberDashboard />;
      default:
        return <Dashboard />;
    }
  };

  const toggleTheme = (checked) => {
    setIsDarkMode(checked);
  };


  // const handleLogout = () => {
  //   setIsAuthenticated(false);
  //   message.info("You have been logged out.");
  // };

  const avatarMenu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Mobile Menu Button */}
      {isMobile && (
        <div 
        style={{
          position: 'fixed',
          top: '16px',
          left: '16px',
          zIndex: 1001,
          background: isDarkMode ? '#2f3136' : '#ffffff',
          borderRadius: '50%',
          padding: '8px',
          transition: 'all 0.2s ease-in-out',
        }}
      >
        <button
          onClick={() => setCollapsed(!collapsed)}
          style={{
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {collapsed ? (
            <MenuIcon 
              size={24}
              color={isDarkMode ? '#ffffff' : '#000000'}
            />
          ) : (
            <CloseIcon 
              size={24}
              color={isDarkMode ? '#ffffff' : '#000000'}
            />
          )}
        </button>
      </div>
      )}

      {/* Backdrop for mobile */}
      {isMobile && !collapsed && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
            zIndex: 999,
          }}
          onClick={() => setCollapsed(true)}
        />
      )}

      {/* Sider */}
      <Sider
        collapsible={!isMobile}
        collapsed={collapsed}
        onCollapse={setCollapsed}
        theme={isDarkMode ? "dark" : "light"}
        style={{
          background: isDarkMode ? "#2f3136" : "#ffffff",
          boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
          height: "100vh",
          position: "fixed",
          left: collapsed && isMobile ? '-240px' : 0,
          top: 0,
          bottom: 0,
          zIndex: 1000,
          transition: 'all 0.3s ease-in-out',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        width={215}
      >
        {/* Logo Section */}
        <div style={{
          color: isDarkMode ? "#fff" : "#000",
          textAlign: "center",
          padding: "16px",
          background: isDarkMode ? "#2f3136" : "#ffffff",
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: `1px solid ${isDarkMode ? '#404040' : '#f0f0f0'}`
        }}>
          <img 
            src="logo192 copy.png" 
            alt="Logo" 
            style={{ 
              maxHeight: '40px', 
              width: 'auto',
              background: isDarkMode ? "#2f3136" : "#ffffff", 
            }} 
          />
        </div>

        {/* Menu Container */}
        <div 
          className="flex flex-col"
          style={{
            height: "calc(95vh - 64px)",
            overflow: 'hidden',
          }}
        >
          <div 
            className="menu-container"
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '100%',
              paddingBottom: '24px'
            }}
          >
            {/* Custom Scrollbar Styles */}
            {/* <style>
              {`
                .menu-container {
                  scrollbar-width: thin;
                  scrollbar-color: ${isDarkMode ? '#666' : '#d9d9d9'} transparent;
                }
                
                .menu-container::-webkit-scrollbar {
                  width: 4px;
                }
                
                .menu-container::-webkit-scrollbar-track {
                  background: transparent;
                }
                
                .menu-container::-webkit-scrollbar-thumb {
                  background-color: ${isDarkMode ? '#666' : '#d9d9d9'};
                  border-radius: 4px;
                }

                .ant-menu-inline .ant-menu-sub {
                  max-height: none !important;
                }

                ${isMobile ? `
                  .ant-menu-item, .ant-menu-submenu-title {
                    height: 48px !important;
                    line-height: 48px !important;
                  }
                ` : ''}
              `}
            </style> */}

            <Menu
              theme={isDarkMode ? "dark" : "light"}
              mode="inline"
              selectedKeys={[selectedMenu]}
              onSelect={({key}) => setSelectedMenu(key)}
              style={{
                border: 'none',
                background: 'transparent',
              }}
            >
              <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                Dashboard
              </Menu.Item>

              <SubMenu key="users" icon={<UserOutlined />} title="Users">
                <Menu.Item key="users">All Users</Menu.Item>
              </SubMenu>

              <SubMenu key="yoga" icon={<DownSquareOutlined />} title="Yoga Class">
                <Menu.Item key="service">Add Group Classes</Menu.Item>
                <Menu.Item key="yoga-instructors">Instructors</Menu.Item>
                <Menu.Item key="Time-Slot">TimeSlot</Menu.Item>
              </SubMenu>

              <SubMenu key="Schedule" icon={<ScheduleOutlined />} title="Schedule">
                <Menu.Item key="yoga-schedule">Add Schedule</Menu.Item>
                <Menu.Item key="CreateDashboard">Schedule Booking</Menu.Item>
              </SubMenu>

              <SubMenu key="booking" icon={<BookOutlined />} title="Booking">
                <Menu.Item key="BookingDashboard">All Bookings</Menu.Item>
                {/* <Menu.Item key="booking-history">Booking History</Menu.Item> */}
              </SubMenu>

              <SubMenu key="event" icon={<CalendarOutlined />} title="Event">
                <Menu.Item key="Events">Add Event</Menu.Item>
                <Menu.Item key="Registration">Registration</Menu.Item>
              </SubMenu>

              <SubMenu key="blog" icon={<BoldOutlined />} title="Blog">
                <Menu.Item key="Post">Add Blog</Menu.Item>
              </SubMenu>

              <SubMenu key="Pricing & Packages" icon={<DollarOutlined />} title="Pricing & Packages">
                <Menu.Item key="PricingDashboard">Pricing & Packages</Menu.Item>
              </SubMenu>
              

              <SubMenu key="contact" icon={<ContactsOutlined />} title="Contact">
                <Menu.Item key="Contact">All Contact</Menu.Item>
                <Menu.Item key="Subscriber">Subscriber</Menu.Item>
              </SubMenu>

              <Menu.Item key="settings" icon={<SettingOutlined />}>
                Settings
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Sider>
      <Layout style={{ 
        marginLeft: isMobile ? 0 : (collapsed ? 80 : 200),
        transition: 'all 0.2s ease-in-out'
      }}>
        {/* Header and Content remain the same */}
        <>
      <Header
        className="sticky top-0 z-10"
        style={{
          padding: isMobile ? '0 16px' : '0 24px',
          background: isDarkMode ? '#333' : '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          height: isMobile ? '64px' : '64px'
        }}
      >
        <div className="flex items-center">
          {isMobile && (
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => onCollapsedChange(!collapsed)}
              style={{ 
                fontSize: '16px', 
                marginRight: '12px',
                color: isDarkMode ? '#fff' : undefined 
              }}
            />
          )}
          
          {!isMobile && (
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              style={{
                width: 220,
                borderRadius: 20,
                padding: '6px 12px'
              }}
            />
          )}
          
          {!isMobile && (
            <Tooltip title="Toggle Dark/Bright Mode">
              <Switch
                checkedChildren={<BulbFilled />}
                unCheckedChildren={<BulbOutlined />}
                onChange={toggleTheme}
                checked={isDarkMode}
                style={{ marginLeft: 16 }}
              />
            </Tooltip>
          )}
        </div>

        <div className="flex items-center gap-3">
          {isMobile && (
            <>
              <Button
                type="text"
                icon={<SearchOutlined />}
                onClick={toggleSearch}
                style={{ color: isDarkMode ? '#fff' : undefined }}
              />
              <Switch
                checkedChildren={<BulbFilled />}
                unCheckedChildren={<BulbOutlined />}
                onChange={toggleTheme}
                checked={isDarkMode}
                size="small"
              />
            </>
          )}
          
          <Dropdown 
            overlay={avatarMenu} 
            placement="bottomRight" 
            trigger={['click']}
          >
            <Avatar 
              icon={<UserOutlined />} 
              style={{ 
                cursor: 'pointer',
                background: isDarkMode ? "#000000" : '#424242' 
              }} 
            />
          </Dropdown>
        </div>
      </Header>

      {/* Mobile Search Drawer */}
      {isMobile && isSearchVisible && (
        <div
          style={{
            padding: '8px 16px',
            background: isDarkMode ? '#333' : '#fff',
            borderBottom: '1px solid',
            borderColor: isDarkMode ? '#424242' : '#f0f0f0',
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: '100%',
              borderRadius: 20,
              padding: '6px 12px'
            }}
          />
        </div>
      )}
    </>
        <Content style={{ margin: isMobile ? "16px 8px" : "24px 16px" }}>
          <div
            style={{
              padding: 24,
              background: isDarkMode ? "#292b2f" : "#ffffff",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
              borderRadius: "8px",
            }}
          >
            <Typography.Title
              level={3}
              style={{ marginBottom: 16, color: isDarkMode ? "#ffffff" : "#000000" }}
            >
              Dashboard
            </Typography.Title>
            {renderContent()}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;