import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PricingDashboard.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://ekamvida.infoorangetech.com/api';

const PricingDashboard = () => {
  const [categories, setCategories] = useState([]);
  const [pricingData, setPricingData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [newItem, setNewItem] = useState({
    category: '',
    name: '',
    originalPrice: 0,
    discountedPrice: '',
    note: '',
    priceUnit: 'HK$',
    priceNote: '',
    sortOrder: 0
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });

  // Fetch all pricing data
  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/pricing`);
        setPricingData(response.data);
        setCategories(Object.keys(response.data));
        setLoading(false);
      } catch (err) {
        setError('Failed to load pricing data. Please try again later.');
        setLoading(false);
        console.error('Error fetching pricing data:', err);
      }
    };

    fetchPricingData();
  }, []);

  // Handle input change for editing
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingItem({
      ...editingItem,
      [name]: name === 'originalPrice' || name === 'discountedPrice' || name === 'sortOrder' 
        ? value === '' ? '' : Number(value) 
        : value
    });
  };

  // Handle input change for new item
  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: name === 'originalPrice' || name === 'discountedPrice' || name === 'sortOrder' 
        ? value === '' ? '' : Number(value) 
        : value
    });
  };

  // Save edited item
  const handleSaveEdit = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/pricing/${editingItem._id}`,
        editingItem
      );
      
      // Update local state with the edited item
      const updatedPricingData = { ...pricingData };
      const categoryItems = updatedPricingData[editingItem.category];
      const itemIndex = categoryItems.findIndex(item => item._id === editingItem._id);
      
      if (itemIndex !== -1) {
        categoryItems[itemIndex] = response.data;
      }
      
      setPricingData(updatedPricingData);
      setEditingItem(null);
      setStatusMessage({ type: 'success', message: 'Package updated successfully!' });
      
      // Clear status message after 3 seconds
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
    } catch (err) {
      console.error('Error updating item:', err);
      setStatusMessage({ type: 'error', message: 'Failed to update package. Please try again.' });
    }
  };

  // Add new pricing item
  const handleAddItem = async () => {
    if (!newItem.name || !newItem.category || newItem.originalPrice < 0) {
      setStatusMessage({ type: 'error', message: 'Please fill in all required fields.' });
      return;
    }
    
    try {
      const response = await axios.post(`${API_URL}/pricing`, newItem);
      
      // Update local state with the new item
      const updatedPricingData = { ...pricingData };
      if (!updatedPricingData[newItem.category]) {
        updatedPricingData[newItem.category] = [];
        setCategories([...categories, newItem.category]);
      }
      
      updatedPricingData[newItem.category].push(response.data);
      setPricingData(updatedPricingData);
      
      // Reset form
      setNewItem({
        category: '',
        name: '',
        originalPrice: 0,
        discountedPrice: '',
        note: '',
        priceUnit: 'HK$',
        priceNote: '',
        sortOrder: 0
      });
      
      setShowAddForm(false);
      setStatusMessage({ type: 'success', message: 'New package added successfully!' });
      
      // Clear status message after 3 seconds
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
    } catch (err) {
      console.error('Error adding item:', err);
      setStatusMessage({ type: 'error', message: 'Failed to add package. Please try again.' });
    }
  };

  // Delete pricing item
  const handleDeleteItem = async (id, category) => {
    if (window.confirm('Are you sure you want to delete this package?')) {
      try {
        await axios.delete(`${API_URL}/pricing/${id}`);
        
        // Update local state by removing the deleted item
        const updatedPricingData = { ...pricingData };
        updatedPricingData[category] = updatedPricingData[category].filter(item => item._id !== id);
        
        // If this was the last item in the category, remove the category
        if (updatedPricingData[category].length === 0) {
          delete updatedPricingData[category];
          setCategories(categories.filter(cat => cat !== category));
        }
        
        setPricingData(updatedPricingData);
        setStatusMessage({ type: 'success', message: 'Package deleted successfully!' });
        
        // Clear status message after 3 seconds
        setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000);
      } catch (err) {
        console.error('Error deleting item:', err);
        setStatusMessage({ type: 'error', message: 'Failed to delete package. Please try again.' });
      }
    }
  };

  // Filter pricing data based on search term and category filter
  const getFilteredPricingData = () => {
    let filteredData = { ...pricingData };
    
    // Filter by category if specified
    if (filterCategory) {
      filteredData = {
        [filterCategory]: pricingData[filterCategory] || []
      };
    }
    
    // Filter by search term if specified
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      
      Object.keys(filteredData).forEach(category => {
        filteredData[category] = filteredData[category].filter(item => 
          item.name.toLowerCase().includes(searchLower) || 
          (item.note && item.note.toLowerCase().includes(searchLower))
        );
      });
      
      // Remove empty categories after filtering
      Object.keys(filteredData).forEach(category => {
        if (filteredData[category].length === 0) {
          delete filteredData[category];
        }
      });
    }
    
    return filteredData;
  };

  if (loading) return <div className="dashboard-loading">Loading pricing data...</div>;
  if (error) return <div className="dashboard-error">{error}</div>;

  const filteredPricingData = getFilteredPricingData();

  return (
    <div className="pricing-dashboard">
      <header className="dashboard-header">
        <h1>Pricing Management</h1>
        <div className="dashboard-controls">
          <div className="search-filter">
            <input
              type="text"
              placeholder="Search packages..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <select 
              value={filterCategory} 
              onChange={(e) => setFilterCategory(e.target.value)}
              className="category-filter"
            >
              <option value="">All Categories</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <button 
            className="add-button"
            onClick={() => setShowAddForm(!showAddForm)}
          >
            {showAddForm ? 'Cancel' : 'Add New Package'}
          </button>
        </div>
      </header>
      
      {statusMessage.message && (
        <div className={`status-message ${statusMessage.type}`}>
          {statusMessage.message}
        </div>
      )}
      
      {showAddForm && (
        <div className="add-form-container">
          <h2>Add New Pricing Package</h2>
          <div className="add-form">
            <div className="form-group">
              <label>Category</label>
              <select
                name="category"
                value={newItem.category}
                onChange={handleNewItemChange}
                required
              >
                <option value="">Select a category</option>
                {categories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
                <option value="new">+ New Category</option>
              </select>
              
              {newItem.category === 'new' && (
                <input
                  type="text"
                  placeholder="Enter new category name"
                  name="category"
                  value={newItem.category === 'new' ? '' : newItem.category}
                  onChange={handleNewItemChange}
                  className="mt-2"
                  required
                />
              )}
            </div>
            
            <div className="form-group">
              <label>Package Name</label>
              <input
                type="text"
                name="name"
                value={newItem.name}
                onChange={handleNewItemChange}
                required
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label>Original Price</label>
                <input
                  type="number"
                  name="originalPrice"
                  value={newItem.originalPrice}
                  onChange={handleNewItemChange}
                  required
                />
              </div>
              
              <div className="form-group">
                <label>Discounted Price (Optional)</label>
                <input
                  type="number"
                  name="discountedPrice"
                  value={newItem.discountedPrice}
                  onChange={handleNewItemChange}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label>Note (Optional)</label>
              <input
                type="text"
                name="note"
                value={newItem.note}
                onChange={handleNewItemChange}
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label>Price Unit</label>
                <input
                  type="text"
                  name="priceUnit"
                  value={newItem.priceUnit}
                  onChange={handleNewItemChange}
                />
              </div>
              
              <div className="form-group">
                <label>Price Note (Optional)</label>
                <input
                  type="text"
                  name="priceNote"
                  value={newItem.priceNote}
                  onChange={handleNewItemChange}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label>Sort Order</label>
              <input
                type="number"
                name="sortOrder"
                value={newItem.sortOrder}
                onChange={handleNewItemChange}
              />
            </div>
            
            <div className="form-actions">
              <button
                type="button"
                className="cancel-button"
                onClick={() => setShowAddForm(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="save-button"
                onClick={handleAddItem}
              >
                Add Package
              </button>
            </div>
          </div>
        </div>
      )}
      
      <div className="pricing-content">
        {Object.keys(filteredPricingData).length === 0 ? (
          <div className="no-results">
            No pricing packages found. {searchTerm && 'Try adjusting your search.'}
          </div>
        ) : (
          Object.keys(filteredPricingData).map(category => (
            <div key={category} className="category-section">
              <h2 className="category-title">{category}</h2>
              <div className="packages-grid">
                {filteredPricingData[category].map(item => (
                  <div key={item._id} className="package-card">
                    {editingItem && editingItem._id === item._id ? (
                      <div className="edit-form">
                        <div className="form-group">
                          <label>Package Name</label>
                          <input
                            type="text"
                            name="name"
                            value={editingItem.name}
                            onChange={handleEditChange}
                          />
                        </div>
                        
                        <div className="form-row">
                          <div className="form-group">
                            <label>Original Price</label>
                            <input
                              type="number"
                              name="originalPrice"
                              value={editingItem.originalPrice}
                              onChange={handleEditChange}
                            />
                          </div>
                          
                          <div className="form-group">
                            <label>Discounted Price</label>
                            <input
                              type="number"
                              name="discountedPrice"
                              value={editingItem.discountedPrice || ''}
                              onChange={handleEditChange}
                            />
                          </div>
                        </div>
                        
                        <div className="form-group">
                          <label>Note</label>
                          <input
                            type="text"
                            name="note"
                            value={editingItem.note || ''}
                            onChange={handleEditChange}
                          />
                        </div>
                        
                        <div className="form-row">
                          <div className="form-group">
                            <label>Price Unit</label>
                            <input
                              type="text"
                              name="priceUnit"
                              value={editingItem.priceUnit}
                              onChange={handleEditChange}
                            />
                          </div>
                          
                          <div className="form-group">
                            <label>Price Note</label>
                            <input
                              type="text"
                              name="priceNote"
                              value={editingItem.priceNote || ''}
                              onChange={handleEditChange}
                            />
                          </div>
                        </div>
                        
                        <div className="form-group">
                          <label>Sort Order</label>
                          <input
                            type="number"
                            name="sortOrder"
                            value={editingItem.sortOrder}
                            onChange={handleEditChange}
                          />
                        </div>
                        
                        <div className="form-actions">
                          <button
                            type="button"
                            className="cancel-button"
                            onClick={() => setEditingItem(null)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="save-button"
                            onClick={handleSaveEdit}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="package-header">
                          <h3>{item.name}</h3>
                          <div className="package-actions">
                            <button
                              className="edit-button"
                              onClick={() => setEditingItem({ ...item })}
                            >
                              Edit
                            </button>
                            <button
                              className="delete-button"
                              onClick={() => handleDeleteItem(item._id, category)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                        
                        <div className="package-details">
                          <div className="price-details">
                            <div className="original-price">
                              <span className="label">Original Price:</span>
                              <span className="value">{item.priceUnit}{item.originalPrice}</span>
                            </div>
                            
                            {item.discountedPrice && (
                              <div className="discounted-price">
                                <span className="label">Discounted Price:</span>
                                <span className="value">{item.priceUnit}{item.discountedPrice}</span>
                              </div>
                            )}
                            
                            {item.priceNote && (
                              <div className="price-note">
                                <span className="value">{item.priceNote}</span>
                              </div>
                            )}
                          </div>
                          
                          {item.note && (
                            <div className="note">
                              <span className="label">Note:</span>
                              <span className="value">{item.note}</span>
                            </div>
                          )}
                          
                          <div className="metadata">
                            <div className="sort-order">
                              <span className="label">Sort Order:</span>
                              <span className="value">{item.sortOrder}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PricingDashboard;