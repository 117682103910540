import React, { useState,useEffect } from "react";
// import { useDispatch, useSelector, } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
// import { logIn } from "../../features/action/auth";
import {toast} from 'sonner'





// ----------------------------------------------------------------------------------
const LoginPage = ({ onLogin }) => {

  // const dispatch = useDispatch();
  
  // const {isLoading}=useSelector((state)=>state.auth)

  const [isPasswordHidden,setPasswordHidden]= useState(true)
 
  const togglePasswordVisibility= ()=>{
    setPasswordHidden(!isPasswordHidden)
    const passwordInput = document.getElementById('hs-toggle-password');
    if(passwordInput){
      passwordInput.type = isPasswordHidden ? "text" : "password"
    }
  }
 
  const {register,handleSubmit,formState: { errors },} =useForm()

  const onSubmit = data=>{
    // const newData = {...data}
    if(data.userName === 'admin' && data.password === 'Admin@123'){
        localStorage.setItem('adminToken', 'dummy-jwt-token');
        // Call success callback
        onLogin(true);
      
    } else {
      toast.error('Incorrect USERNAME/PASSWORD')
    }
    // dispatch(logIn(newData))}
  }

// const handleResetNavigate = ()=>{
//     navigate("/auth/reset")
// }

//  useEffect(()=>{
//   dispatch(clearReduxStoreData())
//  },[])




  return (
    <>
      <div className="pt-20 h-screen rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-wrap items-center">
          <div className="hidden w-full xl:block xl:w-1/2">
            <div className="py-17.5 px-26 text-center">
            <p className="text-2xl">
               Welcome to the Ekamvida Admin Panel
              </p>
              <span className="mt-10 inline-block">
               <img src="022-yoga pose.svg"
               className="w-full h-[340px]"/>
              </span>
            </div>
          </div>

          <div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2 mb-14">
            <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
            
              <h2 className="mb-10 text-2xl  text-black text-center dark:text-white sm:text-title-xl2">
                Sign In 
              </h2>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    UserName
                  </label>
                  <div className="relative">
                    <input
                    {...register("userName",{required: "userName is required"})}
                      type="userName"
                      placeholder="Enter your userName"
                      className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />

                    <span className="absolute right-4 top-4">
                      <svg
                        className="fill-current"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                  {errors.email && (
                    <span className="text-red-500">
                      Email is required
                    </span>
                  )}
                </div>

                <div className="mb-6">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    Password
                  </label>
                  <div className="relative">
                    <input
                    {...register('password',{required:'password is required'})}
                    id="hs-toggle-password"
                      type="password"
                      placeholder="Enter your password"
                      className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />

<button className="text-gray-400 absolute right-[14px] inset-y-0 my-auto active:text-gray-600"
                       type='button'
                       onClick={togglePasswordVisibility}
                >
                    {
                        isPasswordHidden ? (
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>

                        )
                    }
                </button>
                  </div>
                  {errors.password && (
                    <span className="text-red-500">
                      Password is required
                    </span>
                  )}
                </div>

                <div className="">
                <button
                  // disabled={isLoading}
                  className="mt-5 inline-flex w-full items-center justify-center rounded-md bg-[#1D4ED8] px-3.5 py-2.5 font-semibold leading-7 text-white"
                >
                  {/* {isLoading ? (
                  <ClipLoader color="#c4c2c2" />
                ) : (<>  */}
                 Sign In 
                 {/* </>)} */}
                 
                </button>
                  
                </div>

              

             
              </form>
              
              <div className="mt-6 text-center">
                {/* <button
                    // onClick={handleResetNavigate}
                    title=""
                    className="text-sm font-semibold text-black hover:underline"
                  >
                    {" "}
                    Forgot password?{""}
                  </button> */}
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;