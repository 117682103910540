// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Card, Statistic, Row, Col, Table, Tag, Typography, List, message, Grid } from 'antd';
import { UserOutlined, CalendarOutlined, CheckCircleOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { API_BASE_URL } from './config';



const Dashboard = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookClasses, setBookClasses] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    pending: 0,
    completed: 0,
    approved: 0,
    complete: 0,
    approve: 0
  });
  

  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [servicesError, setServicesError] = useState(null);


      // Helper function to safely parse JSON response
      const parseResponse = async (response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return await response.json();
        }
        // If response is not JSON, throw error with text
        throw new Error(await response.text());
      };
  
    useEffect(() => {
      
      fetchServices();
    }, []);
  
  
    const fetchServices = async () => {
      setServicesLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/services`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await parseResponse(response);
        setServices(data);
      } catch (err) {
        setServicesError('Failed to load services');
        console.error('Error fetching services:', err);
      } finally {
        setServicesLoading(false);
      }
    };


    const getServiceName = (serviceId) => {
      const service = services.find(s => s._id === serviceId);
      return service ? service.service_name : 'Unknown Service';
    };

  // Fetch all bookings
  const fetchData = async () => {
    try {
      const [bookingsResponse, bookClassesResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/api/bookings`),
        fetch(`${API_BASE_URL}/api/book-class`)
      ]);

      const bookingsData = await bookingsResponse.json();
      const bookClassesData = await bookClassesResponse.json();

      // Combine and normalize data
      const combinedBookings = [
        ...bookingsData.map(booking => ({
          ...booking,
          type: 'booking',
          clientName: booking.client_name,
          clientEmail: booking.client_email,
          clientPhone: booking.client_phone,
          Time: booking.time,
          serviceName: booking.service?.service_name || 'No Service'
        })),
        ...bookClassesData.map(bookClass => ({
          ...bookClass,
          type: 'book-class',
          clientName: bookClass.clientDetails?.name,
          clientEmail: bookClass.clientDetails?.email,
          clientPhone: bookClass.clientDetails?.phone,
          Time: `${bookClass.schedule?.start_time} - ${bookClass.schedule?.end_time}`,
          serviceName: bookClass.schedule?.service?.service_name || 'No Service'
        }))
      ];

      setBookings(combinedBookings);
      setBookClasses(bookClassesData);
      
      // Calculate combined statistics
      const statistics = combinedBookings.reduce((acc, booking) => {
        acc.total++;
        
        const status = booking.status ? booking.status.toLowerCase() : 'pending';
        
        if (status === 'approve' || status === 'approved') {
          acc.approved++; // Consolidate both into 'approved'
        } else if (status === 'complete' || status === 'completed') {
          acc.completed++; // Consolidate both into 'completed'
        } else {
          acc[status] = (acc[status] || 0) + 1;
        }
      
        return acc;
      }, {
        total: 0,
        pending: 0,
        completed: 0, // Merging complete and completed
        approved: 0,  // Merging approve and approved
        decline: 0,
      });
      
      // Set the state with the cleaned-up statistics
      setStats(statistics);
      setLoading(false);
      
    } catch (error) {
      message.error('Failed to fetch bookings and classes');
      setLoading(false);
    }
  };

  // Update booking status
  const handleStatusUpdate = async (id, status, type) => {
    const endpoint = type === 'booking' 
      ? `${API_BASE_URL}/api/bookings/${id}/status`
      : `${API_BASE_URL}/api/book-class/${id}`;
  
    // Determine the actual status value to send
    const statusValue = type === 'booking' 
      ? status[1] // Use 'approved' or 'declined' for bookings
      : status[0]; // Use 'approve' or 'decline' for book-class
  
    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: statusValue }), // Send the correct status value
      });
  
      if (response.ok) {
        message.success('Status updated successfully');
        fetchData();
      } else {
        message.error('Failed to update status');
      }
    } catch (error) {
      message.error('Error updating status');
    }
  };
  
  useEffect(() => {
    fetchData();
    
    // Set up polling for real-time updates
    const interval = setInterval(fetchData, 30000); // Poll every 30 seconds
    
    return () => clearInterval(interval);
  }, []);


  const columns = [
    {
      title: "Name",
      dataIndex: "clientName",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "clientEmail",
      key: "email",
      ellipsis: true,
      responsive: ['md'],
    },
    {
      title: "Phone",
      dataIndex: "clientPhone",
      key: "phone",
      ellipsis: true,
      responsive: ['lg'],
    },
    {
      title: "Service",
      dataIndex: "serviceName",
      key: "service",
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: "Time",
      dataIndex: "Time",
      key: "time",
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const statusConfig = {
          pending: {
            color: 'orange',
            label: 'Pending'
          },
          approve: {
            color: 'green',
            label: 'Approved'
          },
          approved: {
            color: 'green',
            label: 'Approved', // Same label as decline
          },
          complete: {
            color: 'blue',
            label: 'Completed'
          },
          completed: {
            color: 'blue',
            label: 'Completed'
          },
          decline: {
            color: 'red',
            label: 'Declined'
          },
          declined: {
            color: 'red',
            label: 'Declined'
          }
        };
    
        const { color, label } = statusConfig[status] || statusConfig.pending;
        
        return (
          <div className="flex items-center gap-2">
            <Tag color={color}>{label}</Tag>
            {status === 'pending' && (
              <div className="flex gap-1">
            <button
              onClick={() => handleStatusUpdate(record._id, ['approve', 'approved'], record.type)}
              className="p-1 text-green-600 hover:text-green-800"
            >
              <CheckCircleOutlined />
            </button>
            <button
              onClick={() => handleStatusUpdate(record._id, ['decline', 'declined'], record.type)}
              className="p-1 text-red-600 hover:text-red-800"
            >
              <CloseCircleOutlined />
            </button>

            </div>

            )}
          </div>
        );
      },
    },
  ];

  // const recentBookings = bookings.slice(0, 5).map(booking => ({
  //   name: booking.name,
  //   activity: `Booked ${booking.service?.service_name || 'Service'} - ${booking.status}`
  // }));

  const normalizeStatus = (status) => {
    switch (status) {
      case 'approve':
        return 'approved';
      case 'decline':
        return 'declined';
      case 'complete':
        return 'completed';
      default:
        return status || 'Unknown';
    }
  };
  
  const recentBookings = bookings.slice(0, 5).map(booking => ({
    name: booking.clientName || 'Unknown',
    activity: `Booked ${
      booking.type === 'booking'
        ? booking.service?.service_name || 'No Service'
        : booking.schedule?.service?.service_name || 'No Service'
    } - ${normalizeStatus(booking.status || 'Unknown')}`
  }));
  
  
  

  // Dynamic column spans based on screen size
  const getSpan = (screen) => {
    if (screens.xs) return 24;
    if (screens.sm) return 12;
    return 6;
  };

  const getTableSpan = (screen) => {
    if (screens.xs) return 24;
    if (screens.sm) return 24;
    if (screens.md) return 16;
    return 16;
  };

  return (
    <div className="p-4">
      <Row gutter={[16, 16]}>
        <Col span={getSpan(screens)}>
          <Card 
            bordered={false}
            className="hover:scale-105 transition-transform duration-300"
            style={{
              background: 'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
              borderRadius: '16px',
              boxShadow: '0 4px 20px rgba(99, 102, 241, 0.2)',
            }}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-white text-sm mb-2 opacity-90">Total Bookings</h3>
                <Statistic 
                  value={stats.total} 
                  loading={loading}
                  valueStyle={{ 
                    color: '#ffffff',
                    fontSize: screens.xs ? '24px' : '28px',
                    fontWeight: '600'
                  }}
                />
              </div>
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white/20">
                <UserOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </div>
            </div>
          </Card>
        </Col>

        <Col span={getSpan(screens)}>
          <Card 
            bordered={false}
            className="hover:scale-105 transition-transform duration-300"
            style={{
              background: 'linear-gradient(135deg, #10b981 0%, #059669 100%)',
              borderRadius: '16px',
              boxShadow: '0 4px 20px rgba(16, 185, 129, 0.2)',
            }}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-white text-sm mb-2 opacity-90">Pending</h3>
                <Statistic 
                  value={stats.pending} 
                  loading={loading}
                  valueStyle={{ 
                    color: '#ffffff',
                    fontSize: screens.xs ? '24px' : '28px',
                    fontWeight: '600'
                  }}
                />
              </div>
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white/20">
                <CalendarOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </div>
            </div>
          </Card>
        </Col>

        <Col span={getSpan(screens)}>
          <Card 
            bordered={false}
            className="hover:scale-105 transition-transform duration-300"
            style={{
              background: 'linear-gradient(135deg, #f59e0b 0%, #d97706 100%)',
              borderRadius: '16px',
              boxShadow: '0 4px 20px rgba(245, 158, 11, 0.2)',
            }}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-white text-sm mb-2 opacity-90">Completed</h3>
                <Statistic 
                  value={stats.completed}
                  loading={loading} 
                  valueStyle={{ 
                    color: '#ffffff',
                    fontSize: screens.xs ? '24px' : '28px',
                    fontWeight: '600'
                  }}
                />
              </div>
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white/20">
                <CheckCircleOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </div>
            </div>
          </Card>
        </Col>

        <Col span={getSpan(screens)}>
          <Card 
            bordered={false}
            className="hover:scale-105 transition-transform duration-300"
            style={{
              background: 'linear-gradient(135deg, #f59e0b 0%, #d97706 100%)',
              borderRadius: '16px',
              boxShadow: '0 4px 20px rgba(245, 158, 11, 0.2)',
            }}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-white text-sm mb-2 opacity-90">Approved</h3>
                <Statistic 
                  value={stats.approved}
                  loading={loading} 
                  valueStyle={{ 
                    color: '#ffffff',
                    fontSize: screens.xs ? '24px' : '28px',
                    fontWeight: '600'
                  }}
                />
              </div>
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white/20">
                <CheckCircleOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-6">
        <Col span={getTableSpan(screens)}>
          <Card 
            title="Booking Details"
            bodyStyle={{ padding: 0 }}
          >
            <style>
              {`
                .hide-scrollbar .ant-table-body {
                  overflow-y: auto !important;
                  -ms-overflow-style: none !important;
                  scrollbar-width: none !important;
                }
                
                .hide-scrollbar .ant-table-body::-webkit-scrollbar {
                  display: none !important;
                }
                
                .table-wrapper {
                  overflow: hidden !important;
                }
                
                .ant-table-header {
                  margin-right: 0 !important;
                  padding-right: 0 !important;
                }
                
                .ant-table-header table {
                  margin-bottom: 0 !important;
                }
              `}
            </style>
            
            <div className="table-wrapper">
              <Table
                columns={columns}
                dataSource={bookings}
                loading={loading}
                rowKey="_id"
                pagination={{ 
                  pageSize: screens.xs ? 5 : 4,
                  style: { 
                    margin: '16px 20px'
                  }
                }}
                scroll={{ 
                  y: screens.xs ? 300 : 240,
                  x: 'max-content',
                  scrollToFirstRowOnChange: true 
                }}
                bordered
                className="hide-scrollbar"
                style={{
                  borderRadius: 0
                }}
              />
            </div>
          </Card>
        </Col>

        <Col span={screens.md ? 8 : 24}>
          <Card title="Recent Bookings">
            <List
              loading={loading}
              itemLayout="horizontal"
              dataSource={recentBookings}
              renderItem={(booking) => (
                <List.Item>
                  <List.Item.Meta
                    title={<Typography.Text>{booking.name}</Typography.Text>}
                    description={booking.activity}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
