import React, { useState, useEffect } from 'react';
import { Card } from './ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Loader2 } from "lucide-react";
import { API_BASE_URL } from './config';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/users`);
        const data = await response.json();
        setUsers(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Function to render user cards for mobile view
  const renderMobileCard = (user) => (
    <Card key={user._id} className="mb-4 p-4">
      <div className="space-y-2">
        <div className="flex justify-between">
          <span className="font-medium text-sm text-gray-500">Name</span>
          <span className="text-sm text-right">{`${user.firstName} ${user.lastName}`}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="font-medium text-sm text-gray-500">Email</span>
          <span className="text-sm break-all">{user.email}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="font-medium text-sm text-gray-500">Phone</span>
          <span className="text-sm">{user.phone}</span>
        </div>
      </div>
    </Card>
  );

  // Function to render desktop table
  const renderDesktopTable = () => (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Phone</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!users.length) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-gray-500">
        <p>No users available</p>
      </div>
    );
  }

  return (
    <div className="w-full"  
    style={{
      padding: 24,
      background: isDarkMode ? "#292b2f" : "#ffffff",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
      borderRadius: "8px",
    }}>
      {/* Mobile view */}
      <div className="md:hidden">
        {users.map(renderMobileCard)}
      </div>
      
      {/* Desktop view */}
      <div className="hidden md:block">
        {renderDesktopTable()}
      </div>
    </div>
  );
};

export default Users;