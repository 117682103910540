import React, { useState, useEffect } from 'react';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/react-select';
import axios from 'axios';
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "./ui/alert";
import { API_BASE_URL } from './config';
import { Button } from "./ui/button";

const EventSelect = ({ value, onChange }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [view, setView] = useState('upcoming');

  useEffect(() => {
    fetchEvents();
  }, [view]);

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const endpoint = view === 'upcoming' ? 'upcoming' : 'past';
      const response = await axios.get(`${API_BASE_URL}/api/events/?view=${endpoint}`);
      setEvents(response.data);
    } catch (error) {
      setError('Failed to fetch events');
      console.error('There was an error fetching the events!', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          Failed to load events. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex space-x-2">
        <Button
          variant={view === 'upcoming' ? 'default' : 'outline'}
          onClick={() => setView('upcoming')}
          className="flex-1"
        >
          Upcoming Events
        </Button>
        <Button
          variant={view === 'past' ? 'default' : 'outline'}
          onClick={() => setView('past')}
          className="flex-1"
        >
          Past Events
        </Button>
      </div>

      <Select
        value={value}
        onValueChange={(eventId) => {
          const selectedEvent = events.find(event => event._id === eventId);
          onChange(selectedEvent);
        }}
        disabled={isLoading}
      >
        <SelectTrigger className="w-full">
          <SelectValue 
            placeholder={`Select ${view === 'upcoming' ? 'an upcoming' : 'a past'} event`} 
          />
        </SelectTrigger>
        <SelectContent>
          {isLoading ? (
            <SelectItem value="loading" disabled>
              Loading events...
            </SelectItem>
          ) : events.length === 0 ? (
            <SelectItem value="no-events" disabled>
              No {view} events available
            </SelectItem>
          ) : (
            events.map((event) => (
              <SelectItem key={event._id} value={event._id}>
                {event.title}
              </SelectItem>
            ))
          )}
        </SelectContent>
      </Select>
    </div>
  );
};

export default EventSelect;